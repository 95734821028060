.magic-sider-content-main {

  .magic-side-content {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      .magic-side-header {
        position: relative;
        width: 100%;
        height: 64px;
        background: #002140;
        z-index: 1;
        border-bottom: 1px solid #c1c1c1;
        .magic-side-header-content {
          height: 100%;
          width: 100%;
          color: azure;
        }
      }
      .magic-side-header-collapsed {

      }
      .magic-sider-menu-content {
        height: 100%;
        overflow: hidden;
        .scroll {
          height: 100%;
          overflow: auto;
        }
      }
    }
  }
}