.application-login-content {
  width: 100%;
  height: 100%;
  position: absolute;
  .application-login-content-main {
    position: absolute;
    width: 400px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid red;
    background-color: #ffffff;
    border-radius: 10px;
    .application-login-title {
      font-size: 24px;
      font-weight: 700;
      color: #002140;
    }
    .application-login-proof {
      padding: 20px 0;
    }
  }
}