.App {
    position: absolute;
    width: 100%;
    height: 100%;
    .app-layout {
        width: 100%;
        height: 100%;
        .app-content {
            position: relative;
            margin: 0 16px;
            overflow: auto;
        }
    }
}