.app-magic-form-content-main {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 20px 0;
  .magic-form-main {
    display: flex;
    justify-content: flex-end;
    .magic-form-item-content {
      width: calc(100% - 220px);
      .magic-form-gutter-row {
        margin: 10px 0;
        .magic-form-item-label {
          width: 50px;
        }
        .ant-form-item-label {
          width: 50px;
        }
      }
    }
    .magic-form-button-content {
      width: 200px;
      padding-left: 20px;
      margin: 32px 0;
      .magic-form-button-content-main {
        display: flex;
        justify-content: space-between;
        .magic-form-icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}